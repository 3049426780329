<template>
  <div class="theFooter">
    <!-- footer1 -->
    <div class="theFooter_f1">
      <h1>开启储能行业数智化时代</h1>
      <div class="yuyue_btn" @click="booking">预约体验</div>
    </div>
    <!-- footer2 -->
    <div class="theFooter_f2">
      <div class="theFooter_f2_con w1200">
        <div class="theFooter_f2_con_left">
          <svg class="svg_logo" aria-hidden="true">
            <use xlink:href="#logo-white"></use>
          </svg>
          <div class="footer_info">
            <ul>
              <li class="title">产品服务</li>
              <li><a href="/EMS">轻EMS</a></li>
              <li><a href="/energyguard">储能管家</a></li>
              <li><a href="/doctor">储电医生</a></li>
            </ul>
            <ul>
              <li class="title">关于我们</li>
              <li><a href="/qingzhou">关于轻舟</a></li>
              <li><a href="/news">新闻动态</a></li>
              <li><a href="/join">加入我们</a></li>
            </ul>
            <ul>
              <li class="title">联系我们</li>
              <li class="contact">
                <svg class="svg_footer" aria-hidden="true">
                  <use xlink:href="#phone-fill"></use>
                </svg>
                15867118666 张经理
              </li>
              <li class="contact">
                <svg class="svg_footer" aria-hidden="true">
                  <use xlink:href="#mail-fill"></use>
                </svg>
                info@skiffenergy.com
              </li>
              <li class="contact">
                <svg class="svg_footer" aria-hidden="true">
                  <use xlink:href="#location-fill"></use>
                </svg>
                杭州市余杭区仓前街道鼎创财富中心B2 8层 801
              </li>
            </ul>
          </div>
        </div>
        <div class="theFooter_f2_con_right">
          <div class="qrcode_item">
            <div class="qrcode_item_img">
              <img
                src="../assets/imgs/img-qrcode-a.png"
                alt="关注我们"
                srcset=""
              />
            </div>
            <div class="qrcode_item_label">关注我们</div>
          </div>
          <div class="qrcode_item">
            <div class="qrcode_item_img">
              <img
                src="../assets/imgs/img-qrcode-b.png"
                alt="商务微信"
                srcset=""
              />
            </div>
            <div class="qrcode_item_label">商务微信</div>
          </div>
        </div>
      </div>
      <div class="theFooter_f2_copyright">
        Copyright © 2021 杭州轻舟科技有限公司 浙ICP备16033476号-1
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "the-footer",
  methods: {
    booking() {
      // this.$store.commit("setState", { visible: true });
      window.open(
        `https://www.fqixin.cn/fsh5/smart-form/index.html?cardId=6570209edf8ff0034dc6d51a`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss">
.theFooter {
  &_f1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 168px;
    background-image: url("../assets/imgs/img-bottom-a-blue.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    h1 {
      font-size: 30px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      color: #ffffff;
      margin-right: 56px;
    }
    .yuyue_btn {
      width: 187px;
      height: 56px;
      line-height: 56px;
      background-color: rgba(255, 255, 255, 0.25);
      border: 1px solid rgba(255, 255, 255, 0.15);
      border-radius: 2px;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        background-color: #00c699;
        color: #ffffff;
      }
    }
  }
  &_f2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 627px;
    background-image: url("../assets/imgs/img-bottom-b-black.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 3840px 100%;
    padding-top: 56px;
    &_con {
      display: flex;
      justify-content: space-between;
      .svg_logo {
        width: 162px;
        height: 48px;
      }
      &_left {
        width: 805px;
        text-align: left;
        .footer_info {
          display: flex;
          margin-top: 54px;
          ul {
            margin-right: 138px;
            &:last-child {
              margin-right: 0;
            }
            .title {
              color: #ffffff;
              margin-bottom: 24px;
            }
            li {
              margin-bottom: 16px;
              font-size: 16px;
              font-family: AlibabaPuHuiTi, AlibabaPuHuiTi-Regular;
              font-weight: 400;
              text-align: left;
              color: rgba(255, 255, 255, 0.45);
              a {
                text-decoration: none;
                font-size: 16px;
                font-family: AlibabaPuHuiTi, AlibabaPuHuiTi-Regular;
                font-weight: 400;
                text-align: left;
                color: rgba(255, 255, 255, 0.45);
              }
            }
            .contact {
              display: flex;
              align-items: center;
              .svg_footer {
                width: 18px;
                height: 18px;
                fill: rgba(255, 255, 255, 0.45);
                margin-right: 8px;
              }
            }
          }
        }
      }
      &_right {
        flex: 1;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        &::before {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 2;
          content: "";
          display: block;
          width: 1px;
          height: 202px;
          background-color: rgba(255, 255, 255, 0.15);
        }
        .qrcode_item {
          width: 140px;
          height: 170px;
          &:last-child {
            margin-left: 40px;
          }
          &_img {
            width: 140px;
            height: 140px;
            margin-bottom: 8px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &_label {
            width: 100%;
            height: 22px;
            font-size: 14px;
            font-family: NotoSansSC, NotoSansSC-Regular;
            font-weight: 400;
            text-align: center;
            color: rgba(255, 255, 255, 0.45);
          }
        }
      }
    }
    &_copyright {
      height: 61px;
      line-height: 60px;
      border-top: 1px solid rgba(255, 255, 255, 0.16);
      font-size: 14px;
      font-family: AlibabaPuHuiTiR;
      text-align: center;
      color: rgba(255, 255, 255, 0.45);
    }
  }
}
</style>
