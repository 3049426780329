<template>
  <div class="theNav w1200">
    <slot>
      <svg class="svg_logo" aria-hidden="true">
        <use xlink:href="#logo-white"></use>
      </svg>
    </slot>
    <div class="theNav_end">
      <!-- 弹出 -->
      <ul class="theNav_dropdown">
        <li
          v-for="(item, index) in list"
          :key="'nav_' + index"
          :class="[item.value === is_active && 'is_active']"
        >
          <a :href="`/${item.value}`" v-if="!item.no_href">{{ item.name }}</a>
          <span v-else>{{ item.name }}</span>
          <template v-if="item.children">
            <svg class="svg_down" aria-hidden="true">
              <use xlink:href="#caret-down"></use>
            </svg>
            <ul class="theNav_dropdown_menu">
              <li
                v-for="(child, c_index) in item.children"
                :key="'nav_child_' + c_index"
              >
                <a :href="`/${child.value}`">{{ child.name }}</a>
              </li>
            </ul>
          </template>
        </li>
      </ul>
      <div class="btn" @click="Booking">预约体验</div>
      <!-- m -->
      <div class="svg_m">
        <svg aria-hidden="true" @click="toggle">
          <use :xlink:href="`#${m_menu_icon}`"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "the-nav",
  data() {
    return {
      is_active: "",
      list: [
        {
          name: "首页",
          value: "home",
        },
        {
          name: "产品中心",
          value: "product",
          no_href: true,
          children: [
            {
              name: "轻EMS",
              value: "EMS",
            },
            {
              name: "储能管家",
              value: "energyguard",
            },
            {
              name: "储电医生",
              value: "doctor",
            },
          ],
        },
        // {
        // 	name: "解决方案",
        // 	value: "scheme"
        // },
        {
          name: "关于我们",
          value: "about",
          no_href: true,
          children: [
            {
              name: "关于轻舟",
              value: "qingzhou",
            },
            {
              name: "新闻动态",
              value: "news",
            },
            {
              name: "加入我们",
              value: "join",
            },
          ],
        },
      ],
      m_menu_icon: "menu",
    };
  },
  watch: {
    $route: {
      handler(to) {
        this.is_active = to.name;
      },
      immediate: true,
    },
  },
  methods: {
    // 预约体验
    Booking() {
      // this.$store.commit("setState", { visible: true });
      window.open(
        `https://www.fqixin.cn/fsh5/smart-form/index.html?cardId=6570209edf8ff0034dc6d51a`,
        "_blank"
      );
    },
    // 移动端点击menu
    toggle() {
      this.m_menu_icon = this.m_menu_icon === "menu" ? "close" : "menu";
    },
  },
};
</script>

<style lang="scss">
.theNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  .svg_logo {
    width: 162px;
    height: 48px;
  }
  .svg_down {
    width: 12px;
    height: 12px;
    fill: rgba(255, 255, 255, 0.45);
    margin-left: 8px;
  }
  &_end {
    display: flex;
    align-items: center;
  }
  &_dropdown {
    display: flex;
    align-items: center;
    margin-right: 48px;
    > li {
      &:hover > ul {
        visibility: visible;
        opacity: 1;
      }
      position: relative;
      padding: 0 48px;
      border-right: 1px solid rgba(255, 255, 255, 0.35);
      cursor: pointer;
      > span,
      a {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.65);
        text-decoration: none;
        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }
      > ul {
        position: absolute;
        left: 0;
        top: 34px;
        z-index: 1;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: 0.4s ease-in-out;
      }
    }
    &_menu {
      box-sizing: border-box;
      padding: 0 24px;
      background-color: #ffffff;
      > li {
        padding: 16px 0;
        border-bottom: 1px solid #e6e6e6;
        &:last-child {
          border-bottom: none;
        }
        a {
          display: block;
          color: rgba(0, 0, 0, 0.65);
          text-decoration: none;
        }
        a:hover {
          color: #00c699;
        }
      }
    }
    .is_active {
      color: #00c699;
      .svg_down {
        fill: #00c699;
      }
    }
  }
  .btn {
    white-space: nowrap;
    padding: 5px 24px;
    background-color: rgba(255, 255, 255, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 17px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      background-color: #00c699;
      color: #ffffff;
    }
  }
  .svg_m {
    display: none;
  }
}
</style>
