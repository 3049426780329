<template>
	<div class="theModal">
		<div class="theModal_container">
			<div class="theModal_container_header">
				<span>预约体验</span>
				<svg class="close" aria-hidden="true" @click="cancel">
					<use xlink:href="#close"></use>
				</svg>
			</div>
			<div class="theModal_container_con">
				<div class="tcc_r1">
					<span>感兴趣的产品(多选)</span>
					<div class="tcc_r1_r2">
						<div
							class="checkbox_item"
							v-for="(item, index) in product"
							:key="'pro' + index"
						>
							<input
								type="checkbox"
								:id="item.name"
								v-model="item.checked"
							/>
							<label :for="item.name">{{ item.name }}</label>
						</div>
					</div>
				</div>
				<div
					class="tcc_row"
					v-for="(item, index) in make"
					:key="'make' + index"
				>
					<div class="tcc_row_label">{{ item.name }}：</div>
					<div class="tcc_row_input">
						<input
							type="text"
							v-model="item.value"
							placeholder="请输入"
							v-if="index < 3"
						/>
						<textarea
							v-else
							cols="20"
							rows="10"
							placeholder="请输入"
							v-model="item.value"
						></textarea>
					</div>
				</div>
			</div>
			<div class="theModal_container_footer">
				<div class="btn" @click="cancel">关闭</div>
				<div class="btn btn_2" @click="submit">提交预约</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "the-modal",
		data() {
			return {
				product: [
					{
						name: "轻EMS",
						checked: false
					},
					{
						name: "储能管家",
						checked: false
					},
					{
						name: "储电医生",
						checked: false
					}
				],
				make: [
					{
						name: "姓名",
						value: ""
					},
					{
						name: "手机",
						value: ""
					},
					{
						name: "公司",
						value: ""
					},
					{
						name: "留言",
						value: ""
					}
				]
			};
		},
		methods: {
			cancel() {
				this.product.map(item => (item.checked = false));
				this.make.map(item => (item.value = ""));
				this.$store.commit("setState", { visible: false });
			},
			enableLoading(visible) {
				this.$store.commit("setState", { loading: visible });
			},
			submit() {
				let obj = {
					companyName: "",
					content: "",
					phone: "",
					products: "",
					username: ""
				};

				if (!this.product.some(item => item.checked)) {
					this.$message.info("请选择产品");
					return;
				} else {
					for (let item of this.product) {
						if (item.checked) obj.products += item.name;
					}
				}
				if (this.make.some(item => !item.value)) {
					this.$message.info("请填写完整信息");
					return;
				} else {
					obj.username = this.make[0].value;
					obj.phone = this.make[1].value;
					obj.companyName = this.make[2].value;
					obj.content = this.make[3].value;
				}
				this.enableLoading(true);
				fetch("/api/base/mail", {
					method: "POST",
					headers: {
						"Content-type": "application/json; charset=UTF-8"
					},
					body: JSON.stringify(obj)
				}).then(res => {
					this.enableLoading(false);
					if (res.ok) {
						if (res.status === 200) {
							this.$message.info("预约成功");
							this.cancel();
						} else {
							this.$message.info(res.json().msg);
						}
					} else {
						this.$message.info("请求失败");
					}
				});
			}
		}
	};
</script>

<style lang="scss">
	.theModal {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.65);
		display: flex;
		justify-content: center;
		align-items: center;
		&_container {
			width: 797px;
			height: 556px;
			background-color: #ffffff;
			border-radius: 4px;
			&_header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 55px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.09);
				padding: 0 24px;
				font-size: 16px;
				font-family: PingFangSC, PingFangSC-Medium;
				font-weight: 500;
				color: rgba(0, 0, 0, 0.85);
				.close {
					width: 14px;
					height: 14px;
					fill: rgba(0, 0, 0, 0.25);
					cursor: pointer;
				}
			}
			&_con {
				padding: 32px 96px;
				box-sizing: border-box;
				border-bottom: 1px solid rgba(0, 0, 0, 0.09);
				.tcc_r1 {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					margin-bottom: 32px;
					> span,
					label {
						font-size: 14px;
						font-family: PingFangSC, PingFangSC-Regular;
						font-weight: 400;
						text-align: left;
						color: rgba(0, 0, 0, 0.65);
					}
					&_r2 {
						display: flex;
						align-items: center;
						margin-top: 8px;
						.checkbox_item {
							display: flex;
							align-items: center;
							margin-right: 40px;
							&:last-child {
								margin-right: 0;
							}
							input[type="checkbox"] {
								position: relative;
								width: 16px;
								height: 16px;
								margin-right: 8px;
								cursor: pointer;
							}
							input[type="checkbox"]::after {
								position: absolute;
								display: block;
								content: "";
								width: 16px;
								height: 16px;
								text-align: center;
								border-radius: 2px;
							}
							input[type="checkbox"]:checked::after {
								content: "\2713";
								color: #ffffff;
								line-height: 16px;
								font-weight: bold;
								background-color: #00c699;
							}
						}
					}
				}
				.tcc_row {
					display: flex;
					margin-bottom: 32px;
					&:last-child {
						margin-bottom: 0;
					}
					&_label {
						line-height: 32px;
						font-size: 14px;
						font-family: PingFangSC, PingFangSC-Regular;
						font-weight: 400;
						text-align: left;
						color: rgba(0, 0, 0, 0.65);
					}
					&_input {
						flex: 1;
						input[type="text"] {
							width: 100%;
							height: 100%;
							box-sizing: border-box;
							outline: none;
							padding: 0 16px;
							font-size: 14px;
							font-family: PingFangSC, PingFangSC-Regular;
							font-weight: 400;
							color: rgba(0, 0, 0, 0.65);
							border: 1px solid rgba(0, 0, 0, 0.15);
						}
						textarea {
							width: 100% !important;
							height: 88px;
							outline: none;
							padding: 8px 16px;
							border: 1px solid rgba(0, 0, 0, 0.15);
							box-sizing: border-box;
							resize: none;
							font-size: 14px;
							font-family: PingFangSC, PingFangSC-Regular;
							font-weight: 400;
							color: rgba(0, 0, 0, 0.65);
							border: 1px solid rgba(0, 0, 0, 0.15);
						}
						textarea::placeholder,
						input[type="text"]::placeholder {
							font-size: 14px;
							font-family: PingFangSC, PingFangSC-Regular;
							font-weight: 400;
							text-align: left;
							color: rgba(0, 0, 0, 0.25);
						}
					}
				}
			}
			&_footer {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 70px;
				.btn {
					width: 168px;
					height: 40px;
					line-height: 40px;
					background-color: #ffffff;
					border: 1px solid #d9d9d9;
					border-radius: 2px;
					font-size: 14px;
					font-family: PingFangSC, PingFangSC-Medium;
					font-weight: 500;
					text-align: center;
					color: rgba(0, 0, 0, 0.65);
					cursor: pointer;
				}
				.btn_2 {
					color: #ffffff;
					background-color: #00c699;
					border: none;
					margin-left: 8px;
				}
			}
		}
	}
</style>
