import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: { path: "/home" }
	},
	{
		path: "/home",
		name: "index",
		meta: {
			title: "轻舟能科 - 领航储能数智化"
		},
		component: () => import("@/views/home/index")
	},
	{
		path: "/EMS",
		name: "EMS",
		meta: {
			title: "轻EMS - 轻舟能科官网"
		},
		component: () => import("@/views/EMS/index")
	},
	{
		path: "/energyguard",
		name: "energyguard",
		meta: {
			title: "储能管家 - 轻舟能科官网"
		},
		component: () => import("@/views/energyguard/index")
	},
	{
		path: "/doctor",
		name: "doctor",
		meta: {
			title: "储电医生 - 轻舟能科官网"
		},
		component: () => import("@/views/doctor/index")
	},
	{
		path: "/qingzhou",
		name: "qingzhou",
		meta: {
			title: "关于我们 - 轻舟能科官网"
		},
		component: () => import("@/views/qingzhou/index")
	},
	{
		path: "/news",
		name: "news",
		meta: {
			title: "新闻动态 - 轻舟能科官网"
		},
		component: () => import("@/views/news/index")
	},
	{
		path: "/news/detail",
		name: "newsDetail",
		meta: {
			title: "新闻详情 - 轻舟能科官网"
		},
		component: () => import("@/views/news/detail")
	},
	{
		path: "/join",
		name: "join",
		meta: {
			title: "加入我们 - 轻舟能科官网"
		},
		component: () => import("@/views/qingzhou/join")
	},
	{ path: "*", component: () => import("@/views/notFound/index") }
];

function fetchUVPV() {
	Vue.prototype.$getJSON("/api/statistic/call/me").then(() => {
		console.log("FETCH UVPV");
	});
}
function isPc() {
	let userAgentInfo = window.navigator.userAgent;
	let Agents = [
		"Android",
		"iPhone",
		"SymbianOS",
		"Windows Phone",
		"iPad",
		"iPod"
	];
	let flagPc = true;
	for (let i = 0; i < Agents.length; i++) {
		if (userAgentInfo.indexOf(Agents[i]) > 0) {
			flagPc = false;
			break;
		}
	}
	return flagPc;
}
const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});
router.beforeEach((to, from, next) => {
	if (isPc()) {
		next();
	} else {
		window.open("http://m.skiffenergy.com/", "_self", "", true);
	}
});
router.afterEach(to => {
	document.title = to.meta.title || "轻舟能科官网";
	fetchUVPV();
	window.scrollTo(0, 0);
});

export default router;
